import { exportItems } from '../../constants/arrays';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/modalSharing.scss';

function ModalSharingFastExport({
  selectItem,
  selectedItem,
  selectFastExport,
}) {
  return (
    <div className="fast-export">
      <div className="font-14-med">Fast export</div>
      <div>
        {exportItems.slice(5, 6).map((item) => (
          <div
            className="flex-row cursor-pointer modal-export-download-check"
            onClick={() => {
              selectFastExport(item);
              selectItem(item);
            }}
          >
            {SvgSelector(
              item.id === selectedItem?.id ? 'radioBtnFill' : 'radioBtn',
              'modal-export-radio-icon',
            )}
            <img
              src={item.src}
              width="30"
              height="30"
              alt=""
              className="modal-export-icons"
            />
            <div className="font-14-reg modal-export-download-text">
              {item.title}{' '}
              <span className="modal-export-download-name font-12-reg">
                {item.description}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ModalSharingFastExport;
