import '../../styles/main.css';
import { useRef, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { setTextLimit } from '../../helper/functions';
// import Arrow from '../Elements/Arrow';
import MainMenu from '../Board/MainMenu';
import Footer from '../Elements/Footer';
import { DataContext } from '../../context/DataContext';
import Tree from '../Elements/Tree';
import TaskBoard from './TaskBoard';
import Toast from '../Elements/Toast';
import InviteModal from '../Elements/ModalInvite';
import Loading from '../Elements/Loading';
import OutlineView from '../Elements/OutlineView';
import VerticalMenu from '../Board/VerticalMenu';
import Comments from '../Elements/Comments';
import ModalTheme from '../Elements/ModalTheme';
import AnimationSlide from '../Elements/AnimationSlide';
import useKeyPress from '../../hooks/useKeyPress';
import dataServices from '../../services/dataServices';
import TaskView from '../Elements/TaskView';
// import ProOptionCard from '../ProAccount/ProOptionCard';

function Main({ boardID, loading, layerType, setLoading }) {
  const {
    dispatch,
    treeData,
    board,
    showComments,
    startNode,
    refresh,
    setRefresh,
    inviteModal,
    setInviteModal,
    loadingBoard,
    setLoadingBoard,
    setRefreshBoard,
    treeMultiSelect,
    treeMultiSelectByKey,
    treeMultiSelectList,
    updatePrjArr,
    projectArr,
    calculateProjectsWidth,
    togoMode,
    addToQueue,
    updateTree,
    setCtrlKeyPressed,
    selectedTheme,
    setSelectedTheme,
    undoAction,
    redoAction,
    focusModeKey,
    setStartNode,
  } = useContext(DataContext);
  const itemEls = useRef({});
  const navigate = useNavigate();
  const treeRef = useRef();

  const boardOrder = treeData[boardID]?.order;

  const [inputFocused, setInputFocus] = useState(-1);
  const [selectedNode, selectNode] = useState(-1);
  const [refreshUI, setRefreshUI] = useState(0);
  const [zoom, setZoom] = useState({
    percentName: '100%',
    percentage: 1,
    positionName: -6,
  });
  const [zoomOffset, setZoomOffset] = useState({ x: 0, y: 0 });
  const [viewType, setViewType] = useState('Tree View');
  const [toastInfo, setToastInfo] = useState({
    text: null,
    isLoading: false,
    actionText: 'Okay',
    action: null,
  });
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [isScrolling, setScrolling] = useState(false);
  const [cursor, setCursor] = useState('default');
  const [openThemeMenu, setOpenThemeMenu] = useState(false);

  const showNodeMiddle = boardOrder?.length === 1 && viewType === 'Tree View';
  const mainDiv = useRef();
  const scrollRef = useRef();

  const innerMargin = Math.max(
    (window.innerHeight * 0.8 || 0) - (mainDiv?.current?.clientHeight || 0),
    0,
  );

  useKeyPress((id) => (id === 1 ? undoAction() : redoAction()));

  const keyupHandler = useCallback(
    (event) => {
      if (event.key === 'Control') {
        setCtrlKeyPressed(false);
      } else if (
        inputFocused !== -1 &&
        event.shiftKey &&
        event.code === 'Delete'
      )
        deleteNode(selectedNode);
    },
    [treeMultiSelectList, inputFocused, selectedNode],
  );
  const keydownHandler = useCallback(
    (event) => {
      if (event.key === 'Control') {
        setCtrlKeyPressed(true);
      }
      const { activeElement } = document;
      const isInputFocused =
        activeElement.tagName === 'DIV' || activeElement.tagName === 'INPUT';
      if (event.code === 'Space' && !isInputFocused) {
        if (!focusModeKey) return;
        setStartNode(focusModeKey);
      }
    },
    [focusModeKey],
  );

  useEffect(() => {
    if (selectedNode === -1) dispatch({ type: 'showComment', payload: false });
  }, [selectedNode]);
  useEffect(() => {
    document.addEventListener('keydown', keydownHandler, false);
    document.addEventListener('keyup', keyupHandler, false);

    return () => {
      document.removeEventListener('keydown', keydownHandler, false);
      document.removeEventListener('keyup', keyupHandler, false);
    };
  }, [keyupHandler, focusModeKey]);

  useEffect(() => {
    setRefreshUI((old) => old + 1);
  }, [refresh, treeData]);

  const refreshLayer = () => {
    setRefreshBoard(true);
  };
  const unDelete = (id) => {
    setLoadingBoard(true);
    dataServices.unDeleteLayer(id).then(() => setRefreshBoard(true));
  };
  const deleteNode = (n) => {
    const nodeParent = treeData[n]?.parent;
    const name = treeData[n].title[0].text;
    const idx = treeData[nodeParent]?.order?.indexOf(n);
    const deletedNode = { ...treeData[n], idx };
    const isProject = treeData[n]?.parent === boardID;
    treeData[nodeParent].order = treeData[nodeParent].order.filter(
      (i) => i !== n,
    );
    if (isProject) {
      const tempProject = JSON.parse(JSON.stringify(projectArr));
      delete tempProject[n];
      updatePrjArr(tempProject);
      calculateProjectsWidth();
    }
    setToastInfo({
      isLoading: false,
      text: `${setTextLimit(name)} Deleted`,
      actionText: 'Undo',
      action: unDelete,
      extra: { item: treeData[n].id },
    });
    delete treeData[n];
    addToQueue('delete', deletedNode);
    setInputFocus(-1);
  };

  const copyLink = (node) => {
    navigator?.clipboard?.writeText(`${`${document.location.origin}/${node}`}`);
    setToastInfo({
      isLoading: false,
      text: 'Link copied to clipboard!',
      actionText: 'Okay',
      action: null,
    });
    setInputFocus(-1);
  };

  const shareLayer = (access) => {
    updateTree('public_privilege', access, treeData);
  };
  const onMouseDown = useCallback((e) => {
    if (
      e.target.className === 'nodes' ||
      e.target.className === 'nodes tree-node-children' ||
      e.target.className === 'nodes tree-parent' ||
      e.target.className === 'App nodes' ||
      e.target.className === 'boardMainContainer'
    ) {
      setCursor('grabbing');
      setScrolling(true);
      setClientX(e.clientX);
    }
  }, []);

  const onMouseUp = useCallback(() => {
    setCursor('default');
    setScrolling(false);
  }, []);

  const onMouseMove = useCallback(
    (e) => {
      if (isScrolling) {
        const s = scrollX + clientX - e.clientX;
        const w = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
        scrollRef.current.scrollLeft = s < 0 ? 0 : s > w ? w : s;
        setScrollX(s < 0 ? 0 : s > w ? w : s);
      }
    },
    [isScrolling],
  );

  const duplicateTemplate = (templateId) => {
    setLoadingBoard(true);
    dataServices
      .duplicateTemplate({
        pk: templateId,
      })
      .then((res) => {
        const duplicatedLayer = res.data;
        if (res.status === 200) {
          if (duplicatedLayer.id && duplicatedLayer.id.length > 1) {
            setLoadingBoard(false);
            navigate(`/${duplicatedLayer.id}`);
          } else
            setToastInfo({
              ...toastInfo,
              isLoading: false,
              text: 'Error in fetch data from server!',
              actionText: 'Okay',
              action: null,
            });
        }
      });
  };

  const closeCommentModal = () => {
    dispatch({ type: 'showComment', payload: false });
  };

  const closeBoard = () => {
    // dispatch({ type: 'boardToggle', payload: false });
  };

  return (
    <div className="main-wrapper">
      <div id="navbar-section">
        <MainMenu
          treeRef={treeRef}
          setInputFocus={setInputFocus}
          boardID={boardID}
          setReady={setLoading}
          viewType={viewType}
          setOpenThemeMenu={setOpenThemeMenu}
          toastInfo={toastInfo}
          setToastInfo={setToastInfo}
          duplicateTemplate={duplicateTemplate}
          copyLink={copyLink}
          shareLayer={shareLayer}
        />
      </div>
      {loadingBoard && <Loading />}
      <div
        className={
          viewType === 'Tree View'
            ? selectedTheme === 'dark'
              ? 'boardMainDarkContainer'
              : 'boardMainContainer'
            : 'boardMainContainerOutlineView'
        }
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        ref={scrollRef}
        role="presentation"
        style={{
          zoom,
          position: 'relative',
          overflow: 'scroll',
          height: '100vh',
          cursor,
        }}
      >
        {!loading && (
          <div
            className="main-container-position"
            style={{
              position: 'relative',
              top:
                zoomOffset.y +
                (startNode !== boardID || togoMode || showNodeMiddle
                  ? innerMargin / 2
                  : 0),
              transform: `scale(${zoom.percentage}, ${zoom.percentage}) translate(${zoomOffset.x}%`,
            }}
          >
            <div
              className={`main-container tree ${togoMode ? 'tree-togo' : ''} ${viewType === 'Task View' ? 'main-container-taskView' : ''}`}
              ref={mainDiv}
            >
              {viewType === 'Tree View' && (
                <div ref={treeRef}>
                  <Tree
                    nodes={
                      startNode === boardID && layerType !== 'TASK'
                        ? treeData[startNode]?.order
                        : [startNode]
                    }
                    isFocusMode={startNode !== boardID}
                    MainNode={boardID}
                    itemEls={itemEls}
                    setRefreshUI={setRefreshUI}
                    refreshUI={refreshUI}
                    inputFocused={inputFocused}
                    selectNode={selectNode}
                    setInputFocus={setInputFocus}
                    hasProject={layerType !== 'TASK'}
                    showNotify={setToastInfo}
                    unDelete={unDelete}
                    deleteNode={deleteNode}
                    shareLayer={shareLayer}
                    copyLink={copyLink}
                    scrollRef={scrollRef}
                  />
                </div>
              )}
              {viewType === 'Outline View' && (
                <OutlineView
                  nodes={
                    startNode === boardID && layerType !== 'TASK'
                      ? treeData[startNode]?.order
                      : [startNode]
                  }
                  mainNode={boardID}
                  showNotify={setToastInfo}
                  inputFocused={inputFocused}
                  setInputFocus={setInputFocus}
                  boardID={boardID}
                />
              )}
              {viewType === 'Task View' && (
                <TaskView
                  nodes={
                    startNode === boardID && layerType !== 'TASK'
                      ? treeData[startNode]?.order
                      : [startNode]
                  }
                  mainNode={boardID}
                  showNotify={setToastInfo}
                  inputFocused={inputFocused}
                  setInputFocus={setInputFocus}
                  boardID={boardID}
                  selectedNode={selectedNode}
                  selectNode={selectNode}
                />
              )}
            </div>
          </div>
        )}

        {(treeMultiSelect || treeMultiSelectByKey) && (
          <VerticalMenu setToastInfo={setToastInfo} />
        )}
        {/* <div style={{ position: 'absolute', right: '250px', top: '50%' }}>
          <ProOptionCard proText="Want to export board to file? Sign up for a tariff level right now!" />
        </div> */}
        <Footer
          zoom={zoom}
          setZoom={setZoom}
          setZoomOffset={setZoomOffset}
          viewType={viewType}
          setViewType={setViewType}
          setRefresh={setRefresh}
          treeContainerRef={mainDiv}
          startNode={startNode}
          mainNode={boardID}
          setInputFocus={setInputFocus}
        />
        <AnimationSlide show={board} openMenu={closeBoard} state="rightToLeft">
          <TaskBoard
            selectedNode={selectedNode}
            deleteNode={deleteNode}
            shareLayer={shareLayer}
          />
        </AnimationSlide>
        <AnimationSlide
          show={showComments}
          openMenu={closeCommentModal}
          state="rightToLeft"
        >
          <Comments selectedNode={selectedNode} />
        </AnimationSlide>
      </div>
      <AnimationSlide
        openMenu={setInviteModal}
        show={inviteModal !== false}
        state="topToDown"
      >
        <InviteModal
          openModal={setInviteModal}
          notify={setToastInfo}
          layerToShare={inviteModal}
          RefreshLayer={refreshLayer}
          sendLink={treeData[inviteModal]?.layer_type === 'TASK'}
        />
      </AnimationSlide>
      <AnimationSlide
        show={openThemeMenu}
        openMenu={setOpenThemeMenu}
        state="rightToLeft"
      >
        <ModalTheme
          setOpenThemeMenu={setOpenThemeMenu}
          setSelectedTheme={setSelectedTheme}
        />
      </AnimationSlide>
      {toastInfo.text && (
        <Toast toastInfo={toastInfo} setToastInfo={setToastInfo} />
      )}
    </div>
  );
}

export default Main;
