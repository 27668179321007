import CustomBoardEmoji from '../assets/png/board-custom-icon.png';
import CustomProjectEmoji from '../assets/png/project-custom-icon.png';
import darkTheme from '../assets/png/darkTheme.png';
import defaultTheme from '../assets/png/defaultTheme.png';
import ExcelIcon from '../assets/png/excel.png';
import LayerIcon from '../assets/png/layer.png';
import PngIcon from '../assets/png/png.png';
import PdfIcon from '../assets/png/pdf.png';
import SvgIcon from '../assets/png/svg.png';
import TxtIcon from '../assets/png/txt.png';
import DocxIcon from '../assets/png/docx.png';
import XmlIcon from '../assets/png/xml.png';
import JsonIcon from '../assets/png/json.png';

export const floatMenu = [
  { id: 0, label: 'pencil', tooltip: 'Note' },
  { id: 1, tooltip: 'Status' },
  { id: 2, label: 'calendar', tooltip: 'Calendar' },
  { id: 3, tooltip: 'Color' },
  { id: 4, label: 'spark', tooltip: 'Emoji' },
  { id: 5, label: 'hierarchy', tooltip: 'Open' },
  { id: 6, label: 'copy', tooltip: 'Copy' },
  // { id: 7, label: "attach" },
  { id: 8, label: 'eye', tooltip: 'Focus' },
  { id: 9, label: 'users', tooltip: 'Users' },
  { id: 10, label: 'folder', tooltip: 'Folder' },
  // { id: 9, label: "timer" },
  { id: 11, label: 'upload', tooltip: 'Share' },
  { id: 12, label: 'comment', tooltip: 'Comment' },
  { id: 13, label: 'delete', tooltip: 'Delete' },
];
export const floatMenuTogo = [
  { id: 0, label: 'pencil', tooltip: 'Note' },
  { id: 1, tooltip: 'Status' },
  { id: 2, label: 'calendar', tooltip: 'Calendar' },
  { id: 3, tooltip: 'Color' },
  { id: 4, label: 'spark', tooltip: 'Emoji' },
  { id: 9, label: 'users', tooltip: 'Users' },
];
export const floatMenuFolder = [
  { id: 0, label: 'pencil', tooltip: 'Note' },
  { id: 4, label: 'spark', tooltip: 'Emoji' },
  { id: 8, label: 'eye', tooltip: 'Focus' },
  { id: 10, label: 'folder', tooltip: 'Folder' },
  { id: 13, label: 'delete', tooltip: 'Delete' },
];

export const taskMoreDetails = [
  { id: 1, title: 'Delete', icon: 'delete' },
  { id: 2, title: 'Duplicate', icon: 'copy' },
  { id: 3, title: 'br' },
  { id: 4, title: 'Status', icon: 'delegate' },
  { id: 5, title: 'Set a deadline', icon: 'calendarPlus' },
  // { id: 6, title: 'Add tag', icon: 'hashtag' },
  { id: 7, title: 'Appoint members', icon: 'userProfile' },
  // { id: 8, title: 'Add subtask', icon: 'delete' },
  // { id: 9, title: 'Copy link to the block', icon: 'link' },
];

export const contextMenuItems = [
  { id: 1, label: 'Insert node', shortcut: 'Enter' },
  { id: 2, label: 'Insert subnode', shortcut: 'Tab' },
  { id: 3, label: 'br' },
  { id: 4, label: 'Insert note' },
  { id: 5, label: 'br' },
  { id: 6, label: 'Share', subMenu: true },
  { id: 7, label: 'br' },
  { id: 8, label: 'Copy', shortcut: 'Ctrl + C' },
  { id: 9, label: 'Cut', shortcut: 'Ctrl + X' },
  { id: 10, label: 'Paste', shortcut: 'Ctrl + V' },
  { id: 11, label: 'Duplicate' },
  { id: 12, label: 'Delete', shortcut: 'Shift + D' },
];
export const verticalMenu = [
  { id: 1, label: 'status' },
  { id: 2, label: 'calendar' },
  { id: 3, label: 'palette' },
  { id: 4, label: 'spark' },
  { id: 5, label: 'users' },
  { id: 6, label: 'delete' },
];
export const boardMenu = [
  { id: 0, label: 'task' },
  { id: 1, label: 'calendar' },
  { id: 2, label: 'color' },
  { id: 3, label: 'users' },
  // { id: 4, label: 'folder' },
  { id: 5, label: 'share' },
  { id: 6, label: 'export' },
  { id: 7, label: 'delete' },
  { id: 8, label: 'resize' },
  { id: 9, label: 'close' },
];

export const exportItems = [
  {
    id: 1,
    title: 'Excel(xlsx)',
    description: 'Excel Workbook',
    type: 'excel',
    src: ExcelIcon,
  },
  {
    id: 2,
    title: 'Excel(xlsx) - Vertical',
    description: 'Excel Workbook',
    type: 'vertical-excel',
    src: ExcelIcon,
  },
  {
    id: 3,
    title: 'Layer',
    description: 'Layer format',
    type: 'layer',
    src: LayerIcon,
  },
  {
    id: 4,
    title: 'SVG',
    description: 'Scalable Vector Graphics',
    type: 'svg',
    src: SvgIcon,
  },
  {
    id: 5,
    title: 'PDF',
    description: 'Portable Document Format',
    type: 'pdf',
    src: PdfIcon,
  },
  {
    id: 6,
    title: 'PNG',
    description: 'Portable Network Graphic',
    type: 'png',
    src: PngIcon,
  },
  {
    id: 7,
    title: 'TXT',
    description: 'The most basic text file format',
    type: 'text',
    src: TxtIcon,
  },
  {
    id: 8,
    title: 'DOCX',
    description: 'Microsoft Word documents',
    type: 'doc',
    src: DocxIcon,
  },
  {
    id: 9,
    title: 'XML',
    description: 'Extensible Markup Language',
    type: 'xml',
    src: XmlIcon,
  },
  {
    id: 10,
    title: 'JSON',
    description: 'JavaScript Object Notation',
    type: 'json',
    src: JsonIcon,
  },
];

export const noStatus = {
  id: 1,
  label: 'status',
  value: 'NO_STATUS',
  name: 'No status',
  color: 'gray_06',
};

export const floatMenuStatus = {
  TOGO: {
    id: 1,
    label: 'togo',
    value: 'TOGO',
    name: 'To go',
    color: 'yellow_light',
  },
  WORK_IN_PROGRESS: {
    id: 2,
    label: 'delegate',
    value: 'WORK_IN_PROGRESS',
    name: 'Work in progress',
    color: 'blue_light',
  },
  DONE: {
    id: 3,
    label: 'done',
    value: 'DONE',
    name: 'Done',
    color: 'green_light',
  },
  ASSIGNED_TO_ME: {
    id: 4,
    label: 'assignedToMe',
    value: 'ASSIGNED_TO_ME',
    name: 'Assigned to me',
    color: 'lavender_light',
  },
  EXPIRE_TODAY: {
    id: 5,
    label: 'expireToday',
    value: 'EXPIRE_TODAY',
    name: 'Expire today',
    color: 'red_light',
  },
  BACKLOG: {
    id: 6,
    label: 'backlog',
    value: 'BACKLOG',
    name: 'Backlog',
    color: 'gray_06',
  },
};

export const colorPanel = [
  { id: 0, color: 'red', setColor: 'RED' },
  { id: 1, color: 'coral', setColor: 'CORAL' },
  { id: 2, color: 'orange', setColor: 'ORANGE' },
  { id: 3, color: 'yellow', setColor: 'YELLOW' },
  { id: 4, color: 'green', setColor: 'GREEN' },
  { id: 5, color: 'blue', setColor: 'BLUE' },
  { id: 6, color: 'purple', setColor: 'PURPLE' },
  { id: 7, color: 'violet', setColor: 'VIOLET' },
  { id: 8, color: 'pink', setColor: 'PINK' },
  { id: 9, color: 'gray', setColor: 'GRAY' },
  { id: 10, color: 'light_gray', setColor: 'LIGHT_GRAY' },
];

export const colorPanelTextEditor = [
  { id: 0, color: 'red', setColor: 'red' },
  { id: 1, color: 'orange', setColor: 'orange' },
  { id: 2, color: 'yellow', setColor: 'yellow' },
  { id: 3, color: 'green', setColor: 'green' },
  { id: 4, color: 'blue', setColor: 'blue' },
  { id: 5, color: 'purple', setColor: 'purple' },
  { id: 6, color: 'pink', setColor: 'pink' },
];

export const colorPanelNote = [
  {
    id: 0,
    color: 'red',
    setColor: 'red_light',
    code: '#FF3B30',
  },
  {
    id: 1,
    color: 'coral',
    setColor: 'coral_light',
    code: '#FF6746',
  },
  {
    id: 2,
    color: 'orange',
    setColor: 'orange_light',
    code: '#FF9500',
  },
  {
    id: 3,
    color: 'yellow',
    setColor: 'yellow_light',
    code: '#FFCC00',
  },
  {
    id: 4,
    color: 'lime',
    setColor: 'lime_light',
    code: '#70E852',
  },
  {
    id: 5,
    color: 'green',
    setColor: 'green_light',
    code: '#34C759',
  },
  {
    id: 6,
    color: 'blue',
    setColor: 'blue_light',
    code: '#18A0FB',
  },
  {
    id: 7,
    color: 'blue_strong',
    setColor: 'blue_strong_light',
    code: '#007AFF',
  },
  {
    id: 8,
    color: 'purple',
    setColor: 'purple_light',
    code: '#5856D6',
  },
  {
    id: 9,
    color: 'lavender',
    setColor: 'lavender_light',
    code: '#AF52DE',
  },
  {
    id: 10,
    color: 'pink_strong',
    setColor: 'pink_strong_light',
    code: '#FF2D55',
  },
  {
    id: 11,
    color: 'pink',
    setColor: 'pink_light',
    code: '#FF2DD1',
  },
  {
    id: 12,
    color: 'black',
    setColor: 'black',
    code: '#000000',
  },
];

export const sendOptions = [
  {
    id: 0,
    label: 'link',
    name: 'Copy link',
    color: 'gray_06',
  },
  {
    id: 1,
    label: 'mail',
    name: 'Send to email',
    color: 'yellow_light',
  },
];

export const taskBoardBlocksMenu = [
  { id: 1, label: 'Delete', img: 'delete', shortcut: 'Del or Ctrl+D' },
  { id: 2, label: 'Duplicate', img: 'copy', shortcut: 'Ctrl+V' },
  { id: 3, label: 'br' },
  { id: 4, label: 'Select a block', img: 'refreshBlock', subMenu: true },
  { id: 5, label: 'Make it a node', img: 'gitBranch', shortcut: 'Ctrl+N' },
  {
    id: 6,
    label: 'Copy link to the block',
    img: 'link',
    shortcut: 'Ctrl+C',
  },
];
export const taskBoardBlocksMenuBase = [
  { id: 1, type: 'base', label: 'Text', img: 'text', itemIndex: 5 },
  {
    id: 2,
    type: 'base',
    label: 'Small text',
    img: 'textSmall',
    shortcut: 'Ctrl+S+T',
    itemIndex: 5,
  },
  {
    id: 3,
    type: 'base',
    label: 'Headline 1',
    img: 'titleH1',
    shortcut: 'Ctrl+H+1',
    itemIndex: 0,
  },
  {
    id: 4,
    type: 'base',
    label: 'Headline 2',
    img: 'titleH2',
    shortcut: 'Ctrl+H+2',
    itemIndex: 1,
  },
  {
    id: 5,
    type: 'base',
    label: 'Headline 3',
    img: 'titleH3',
    shortcut: 'Ctrl+H+3',
    itemIndex: 2,
  },
  {
    id: 6,
    type: 'base',
    label: 'Bulleted list',
    img: 'pointList',
    itemIndex: 4,
  },
  {
    id: 7,
    type: 'base',
    label: 'Numbered list',
    img: 'numberList',
    itemIndex: 3,
  },
  {
    id: 8,
    type: 'base',
    label: 'Toggle list',
    img: 'arrowRight',
    itemIndex: -1,
  },
  { id: 9, type: 'base', label: 'Callout', img: 'star', itemIndex: -1 },
];
export const taskBoardBlocksMenuMedia = [
  {
    id: 1,
    type: 'media',
    label: 'Image',
    img: 'image',
    shortcut: 'Ctrl+I',
    itemIndex: -1,
  },
  {
    id: 2,
    type: 'media',
    label: 'Emoji',
    img: 'emoji',
    shortcut: 'Ctrl+E',
    itemIndex: -1,
  },
  {
    id: 3,
    type: 'media',
    label: 'File',
    img: 'newFile',
    shortcut: 'Ctrl+F',
  },
];
export const taskBoardBlocksMenuAction = [
  {
    id: 8,
    type: 'action',
    label: 'Check list',
    img: 'done',
    shortcut: 'Ctrl+C',
    itemIndex: 8,
  },
  {
    id: 2,
    type: 'action',
    label: 'Node',
    img: 'gitBranch',
    shortcut: 'Ctrl+N',
    itemIndex: -1,
  },
  {
    id: 3,
    type: 'action',
    label: 'Mention a person',
    img: 'profile',
    shortcut: '@+name',
    itemIndex: -1,
  },
];

export const noteStylesMenuItems = [
  {
    id: 1,
    name: 'RemoveFormat',
    img: 'removeFormat',
    iconClass: 'modalStyleNoteItemStroke',
    status: 'null',
  },
  {
    id: 2,
    name: 'Bold',
    value: 'b',
    img: 'bold',
    iconClass: 'modalStyleNoteItemFill',
    status: 'bold',
    active: true,
  },
  {
    id: 3,
    name: 'Italics',
    value: 'i',
    img: 'italics',
    iconClass: 'modalStyleNoteItemFill',
    status: 'italic',
    active: true,
  },
  {
    id: 4,
    name: 'Underline',
    value: 'u',
    img: 'underline',
    iconClass: 'modalStyleNoteItemFill',
    status: 'underline',
    active: true,
  },
  {
    id: 5,
    name: 'Strike',
    value: 's',
    img: 'strike',
    iconClass: 'modalStyleNoteItemFill',
    status: 'strike',
    active: true,
  },

  {
    id: 6,
    name: 'AlignRight',
    img: 'alignLeft',
    iconClass: 'modalStyleNoteItemFill',
    status: 'textAlignment',
    active: 'left',
  },
  {
    id: 7,
    name: 'AlignCenter',
    img: 'alignCenter',
    iconClass: 'modalStyleNoteItemFill',
    status: 'textAlignment',
    active: 'center',
  },
  {
    id: 8,
    name: 'AlignLeft',
    img: 'alignRight',
    iconClass: 'modalStyleNoteItemFill',
    status: 'textAlignment',
    active: 'right',
  },
  {
    id: 9,
    name: 'ColorPicker',
    img: 'colorPicker',
    iconClass: 'modalStyleNoteItemFill',
    status: 'color',
    active: true,
  },
  {
    id: 10,
    name: 'Link',
    img: 'link',
    iconClass: 'modalStyleNoteItemStroke',
    status: 'link',
    active: true,
  },
];

export const modalDocument = [
  { id: 1, name: 'Delete', img: 'delete' },
  { id: 2, name: 'Duplicate', img: 'copy' },
  { id: 3, name: 'Save', img: 'save' },
];

export const dashboardMainMenu = [
  { id: 1, name: 'My boards', img: 'myBoards' },
  // { id: 2, name: "New", img: "timer" },
  // { id: 3, name: 'Public', img: 'earth' },
  { id: 4, name: 'Deleted', img: 'delete' },
  { id: 5, name: 'My tasks', img: 'checkBroken' },
  { id: 6, name: 'Templates', img: 'temp' },
];

export const dashboardAddProjectMenu = [
  { id: 1, name: 'New board', img: 'newFile' },
  { id: 2, name: 'New project', img: 'folder' },
  { id: 3, name: 'Import board', img: 'save' },
];

export const dashboardChangeView = [
  { id: 1, name: 'Grid', img: 'gridView' },
  { id: 2, name: 'Lineage', img: 'lineageView' },
];

export const dashboardProfileMenu = [
  { id: 1, name: 'Account', img: 'account' },
  // { id: 2, name: "Team", img: "team" },
  { id: 3, name: 'br', img: 'br' },
  { id: 6, name: 'Change Theme', img: 'contrast' },
  { id: 5, name: 'Log out', img: 'logout' },
];

export const dashboardProjectMoreMenu = [
  { id: 1, name: 'Open', img: 'folderOpen' },
  { id: 2, name: 'Rename', img: 'rename' },
  { id: 3, name: 'Duplicate', img: 'copy' },
  { id: 4, name: 'Members', img: 'members' },
  { id: 5, name: 'Relocate', img: 'refresh' },
  { id: 6, name: 'br', img: 'br' },
  { id: 7, name: 'Delete', img: 'delete' },
];
export const dashboardDeletedMoreMenu = [
  { id: 8, name: 'Delete', img: 'delete' },
  { id: 9, name: 'Restore', img: 'refresh' },
];

export const accountHeaderTabs = [
  { id: 1, name: 'Account' },
  // { id: 2, name: "Team" },
];

export const reminderItems = [
  { id: 0, name: 'None', time: null },
  { id: 1, name: 'At time of due date', time: 0 },
  { id: 2, name: '5 Minutes before', time: 5 },
  { id: 3, name: '10 Minutes before', time: 10 },
  { id: 4, name: '15 Minutes before', time: 15 },
  { id: 5, name: '1 Hour before', time: 60 },
  { id: 6, name: '2 Hours before', time: 2 * 60 },
  { id: 7, name: '1 Day before', time: 24 * 60 },
  { id: 8, name: '2 Days before', time: 2 * 24 * 60 },
];
export const scaleSelector = [
  {
    id: 1,
    name: '10%',
    scale: 0.1,
    offsetX: -448,
    offsetY: 43,
    positionName: 23,
  },
  {
    id: 2,
    name: '20%',
    scale: 0.2,
    offsetX: -199,
    offsetY: 38,
    positionName: 19,
  },
  {
    id: 3,
    name: '30%',
    scale: 0.3,
    offsetX: -116.5,
    offsetY: 33,
    positionName: 17,
  },
  {
    id: 4,
    name: '40%',
    scale: 0.4,
    offsetX: -75,
    offsetY: 28,
    positionName: 14,
  },
  {
    id: 5,
    name: '50%',
    scale: 0.5,
    offsetX: -50,
    offsetY: 23,
    positionName: 10,
  },
  {
    id: 6,
    name: '60%',
    scale: 0.6,
    offsetX: -33.5,
    offsetY: 18,
    positionName: 8,
  },
  {
    id: 7,
    name: '70%',
    scale: 0.7,
    offsetX: -21.5,
    offsetY: 13,
    positionName: 4,
  },
  {
    id: 8,
    name: '80%',
    scale: 0.8,
    offsetX: -12.5,
    offsetY: 8,
    positionName: 1,
  },
  {
    id: 9,
    name: '90%',
    scale: 0.9,
    offsetX: -5.5,
    offsetY: 4,
    positionName: -3,
  },
  {
    id: 10,
    name: '100%',
    scale: 1,
    offsetX: 0,
    offsetY: 0,
    positionName: -6,
  },
  {
    id: 11,
    name: '110%',
    scale: 1.1,
    offsetX: 4.5,
    offsetY: -3,
    positionName: -11,
  },
  {
    id: 12,
    name: '120%',
    scale: 1.2,
    offsetX: 8.2,
    offsetY: -8,
    positionName: -13,
  },
  {
    id: 13,
    name: '150%',
    scale: 1.3,
    offsetX: 11.5,
    offsetY: -12,
    positionName: -16,
  },
  {
    id: 14,
    name: '200%',
    scale: 1.4,
    offsetX: 14.1,
    offsetY: -18,
    positionName: -20,
  },
  {
    id: 15,
    name: '300%',
    scale: 1.5,
    offsetX: 16.5,
    offsetY: -25,
    positionName: -23,
  },
];

export const customEmojis = [
  {
    names: ['Board'],
    imgUrl: CustomBoardEmoji,
    id: 'layer-board',
  },
  {
    names: ['Project'],
    imgUrl: CustomProjectEmoji,
    id: 'layer-project',
  },
];

export const typeViewSelector = [
  { id: 1, name: 'Tree View' },
  { id: 2, name: 'Outline View' },
  { id: 3, name: 'Task View' },
];
export const avatarColors = [
  '#af52de',
  '#5856d6',
  '#34c759',
  '#ff2dd1',
  '#ff6746',
  '#ff2d55',
  '#007aff',
];

export const themeList = [
  { id: 1, name: 'default', background: defaultTheme },
  { id: 2, name: 'dark', background: darkTheme },
];

export const levelItems = [
  { id: 1, title: 'Short nodes', img: 'shortNodes', status: false },
  { id: 2, title: 'Tree with levels', img: 'levels', status: true },
];

export const skinEmojiList = [
  { skin: 1, hand: '270b' },
  { skin: 2, hand: '270b-1f3fb' },
  { skin: 3, hand: '270b-1f3fc' },
  { skin: 4, hand: '270b-1f3fd' },
  { skin: 5, hand: '270b-1f3fe' },
  { skin: 6, hand: '270b-1f3ff' },
];

export const checkListMenuItem = [
  { id: 1, icon: 'delete', name: 'Delete', keyName: 'Del or cntrl+D' },
  { id: 2, icon: 'copy', name: 'Duplicate', keyName: 'Cntrl + V' },
  { id: 3, icon: '', name: 'br', keyName: '' },
  {
    id: 4,
    icon: 'calendarPlus',
    name: 'Set a deadline',
    keyName: 'chevronRight',
  },
  {
    id: 5,
    icon: 'userProfile',
    name: 'Appoint a person',
    keyName: 'chevronRight',
  },
  // {
  //   id: 6,
  //   icon: 'link',
  //   name: 'Copy link to the block',
  //   keyName: 'Cntrl + C',
  // },
];

export const textEditorTabs = {
  base: 'Base',
  // media: 'Media',
  action: 'Action',
};

export const baseBlocks = [
  { id: 1, icon: 'text', key: '', name: 'Text', blockType: 'paragraph' },
  {
    id: 2,
    icon: 'typePlus',
    key: '⌘+S+T',
    name: 'Small text',
    blockType: null,
  },
  {
    id: 3,
    icon: 'header1',
    key: '⌘+H+1',
    name: 'Headline 1',
    blockType: 'heading',
    level: 1,
  },
  {
    id: 4,
    icon: 'header2',
    key: '⌘+H+2',
    name: 'Headline 2',
    blockType: 'heading',
    level: 2,
  },
  {
    id: 5,
    icon: 'header3',
    key: '⌘+H+3',
    name: 'Headline 3',
    blockType: 'heading',
    level: 3,
  },
  {
    id: 6,
    icon: 'bullet',
    key: '',
    name: 'Buleted list',
    blockType: 'bulletListItem',
  },
  {
    id: 7,
    icon: 'number',
    key: '',
    name: 'Numbered list',
    blockType: 'numberedListItem',
  },
  // {
  //   id: 8,
  //   icon: 'toggle',
  //   key: '',
  //   name: 'Toggle list',
  //   blockType: null,
  // },
  // { id: 9, icon: 'callout', key: '', name: 'Callout', blockType: null },
];

export const mediaBlocks = [
  { id: 1, icon: 'image', key: 'Cntrl+I', name: 'Image' },
  { id: 2, icon: 'emoji', key: '⌘+E', name: 'Emoji' },
  { id: 3, icon: 'newFile', key: 'Cntrl+F', name: 'File' },
];

export const actionBlocks = [
  {
    id: 1,
    icon: 'checkList',
    key: 'Cntrl+C',
    name: 'Check list',
    blockType: 'checkList',
  },
  { id: 2, icon: 'node', key: 'Cntrl+N', name: 'Node', blockType: 'node' },
  // { id: 3, icon: 'person', key: '@+ neme', name: 'Mention a person' },
];

export const moreMenuTextEditor = [
  { id: 1, icon: 'delete', name: 'Delete', key: 'Del or Cntrl+D' },
  { id: 2, icon: 'copy', name: 'Duplicate', key: '⌘+V' },
  { id: 3, icon: 'br', name: 'br', key: '' },
  { id: 4, icon: 'refresh', name: 'Select a block', key: 'chevronRight' },
  { id: 5, icon: 'gitBranch', name: 'Make it a node', key: 'Cntrl+N' },
  { id: 6, icon: 'link', name: 'Copy link to the block', key: 'Cntrl+C' },
];

export const tabsBoardItem = [
  { id: 1, name: 'All', key: 'all' },
  { id: 2, name: 'Shared with me', key: 'sharedWithMe' },
  { id: 3, name: 'Shared by me', key: 'sharedByMe' },
];

export const templateTabs = [
  { id: 1, name: 'All', key: 'all' },
  { id: 2, name: 'Marketing', key: 'marketing' },
  { id: 3, name: 'Development', key: 'development' },
  { id: 4, name: 'Business', key: 'business' },
  { id: 5, name: 'Design', key: 'design' },
  { id: 6, name: 'Personal', key: 'personal' },
];

export const nodesMoreMenu = [
  { id: 1, name: 'Delete', key: 'Del or Cntr+D', icon: 'delete' },
  { id: 2, name: 'Duplicate', key: 'Cntrl+V', icon: 'copy' },
  { id: 3, name: 'br', key: 'br', icon: 'br' },
  {
    id: 4,
    name: 'Status',
    key: 'chevronRight',
    icon: 'checkStatus',
  },
  {
    id: 5,
    name: 'Set a deadline',
    key: 'chevronRight',
    icon: 'calendarPlus',
  },
  {
    id: 6,
    name: 'Color',
    key: 'chevronRight',
    icon: 'colorPicker',
  },
  {
    id: 7,
    name: 'Appoint a person',
    key: 'chevronRight',
    icon: 'userProfile',
  },
  // { id: 8, name: 'Make it a folder', key: '', icon: 'folder' },
  // { id: 9, name: 'Make it a check list', key: '', icon: 'brokenCheck' },
  // { id: 10, name: 'Copy link to the block', key: 'Cntrl+C', icon: 'link' },
];

export const nodesColorPanel = [
  { id: 1, color: 'red', setColor: 'Red', background: 'RED' },
  { id: 2, color: 'coral', setColor: 'Coral', background: 'CORAL' },
  { id: 3, color: 'orange', setColor: 'Orange', background: 'ORANGE' },
  { id: 4, color: 'yellow', setColor: 'Yellow', background: 'YELLOW' },
  { id: 5, color: 'green', setColor: 'Green', background: 'GREEN' },
  { id: 6, color: 'blue', setColor: 'Blue', background: 'BLUE' },
  { id: 7, color: 'purple', setColor: 'Purple', background: 'PURPLE' },
  { id: 8, color: 'lavender', setColor: 'Violet', background: 'LAVENDER' },
  {
    id: 9,
    color: 'pink_strong',
    setColor: 'Bright pink',
    background: 'PINK_STRONG',
  },
  { id: 10, color: 'pink', setColor: 'Pink', background: 'PINK' },
];

export const taskBoardFilter = [
  {
    id: 1,
    text: 'Projects',
    tooltiptext: 'Select projects',
    grid: '35%',
    tooltipClass: 'task-board-header-tooltip',
  },
  {
    id: 2,
    text: 'Boards',
    tooltiptext: 'Select boards',
    grid: '15%',
    tooltipClass: 'task-board-header-tooltip',
  },
  {
    id: 3,
    text: 'Deadline',
    tooltiptext: 'Deadline filter',
    grid: '12%',
    tooltipClass: 'task-board-header-tooltip',
  },
  {
    id: 4,
    text: 'Status',
    tooltiptext: 'Select statuses',
    grid: '7%',
    tooltipClass: 'task-board-header-status',
  },
  {
    id: 5,
    text: 'Tags',
    tooltiptext: 'Select tags',
    grid: '14%',
    tooltipClass: 'task-board-header-tags',
  },
  {
    id: 6,
    text: 'Members',
    tooltiptext: 'Select members',
    grid: '13%',
    tooltipClass: 'task-board-header-member',
  },
];

export const modalTitle = [
  { id: 1, name: 'Title H1', img: 'titleH1' },
  { id: 2, name: 'Title H2', img: 'titleH2' },
  { id: 3, name: 'Title H3', img: 'titleH3' },
  { id: 4, name: 'Text', img: 'text' },
  // { id: 5, name: "File", img: "file" },
  { id: 6, name: 'Point List', img: 'pointList' },
  { id: 7, name: 'Number List', img: 'numberList' },
  { id: 8 },
  { id: 9, name: 'Delete', img: 'delete' },
  { id: 10, name: 'Duplicate', img: 'copy' },
];

export const sharingModalTabs = [
  { id: 1, text: 'Share' },
  { id: 2, text: 'Invite' },
  { id: 3, text: 'Export' },
];

export const accessList = [
  { id: 1, text: 'Can view', icon: 'eye', value: 'READ' },
  { id: 2, text: 'Can edit', icon: 'pencil', value: 'WRITE' },
  { id: 3, text: 'No access', icon: 'eyeClose', value: 'NO_ACCESS' },
];

export const accessName = {
  NO_ACCESS: 'No access',
  WRITE: 'Can edit',
  READ: 'Can view',
};
