import { useState } from 'react';
// import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { SvgSelector } from '../../helper/svg-selector';
import '../../styles/modalSharing.scss';
import ModalSharingCopyLink from './ModalSharingCopyLink';
import ModalSharingFastExport from './ModalSharingFastExport';
import Button from './Button';
import ModalExport from './ModalExport';
import { sharingModalTabs, exportItems } from '../../constants/arrays';
import ModalSharingInvite from './ModalSharingInvite';

function ModalSharing({
  setOpenModal,
  // boardID,
  // treeRef,
  boardName,
  shareLayer,
  copyLink,
  node,
  showNotify,
}) {
  const [selectedTab, setSelectedTab] = useState('Share');
  const [selectedFastExport, selectFastExport] = useState(null);
  const [selectedItem, selectItem] = useState(exportItems[0]);
  const [isLoading, setIsLoading] = useState(false);

  const exportHandler = () => {
    if (isLoading) return;
    // if (selectedItem.id === 4) exportSvg();
    // else if (selectedItem.id === 5) exportPdf();
    if (selectedItem.id === 6) {
      exportPng();
      setIsLoading(true);
    }
    // else exportOtherFormats();
  };

  // const exportOtherFormats = () => {
  //   setOpenModal(false);
  //   window.location.href = `${process.env.REACT_APP_BASE_URL}api/layers/${boardID}/export/${selectedItem.type}/`;
  //   setIsLoading(false);
  // };

  function exportPng() {
    const floatMenu = document.getElementById('float-menu');
    floatMenu.style.display = 'none';
    const currentLayer = document.querySelectorAll(`[data-key="${node}"]`);
    htmlToImage
      .toPng(currentLayer[0], { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${boardName}.png`;
        link.href = dataUrl;
        link.click();
        setIsLoading(false);
        setOpenModal(false);
        floatMenu.style.display = 'block';
      })
      .catch(() => {
        setIsLoading(false);
        setOpenModal(false);
        floatMenu.style.display = 'block';
      });
  }

  // function exportPdf() {
  //   const floatMenu = document.getElementById('float-menu');
  //   floatMenu.style.display = 'none';
  //   const currentLayer = document.querySelectorAll(`[data-key="${node}"]`);
  //   // const node = treeRef.current;
  //   htmlToImage
  //     .toPng(currentLayer[0], {
  //       cacheBust: true,
  //       canvasWidth: node.clientWidth * 3,
  //       canvasHeight: node.clientHeight * 3,
  //     })
  //     .then(function (dataUrl) {
  //       // eslint-disable-next-line new-cap
  //       const pdf = new jsPDF({
  //         orientation:
  //           treeRef.current.clientWidth > treeRef.current.clientHeight
  //             ? 'l'
  //             : 'p',
  //         format: [node.clientWidth, node.clientHeight],
  //       });
  //       const pdfWidth = pdf.internal.pageSize.getWidth();
  //       const pdfHeight = pdf.internal.pageSize.getHeight();
  //       pdf.addImage(dataUrl, 'PNG', 0, 1, pdfWidth, pdfHeight);
  //       pdf.save(`${boardName}.pdf`);
  //       setIsLoading(false);
  //       setOpenModal(false);
  //       floatMenu.style.display = 'block';
  //     })
  //     .catch(() => {
  //       setIsLoading(false);
  //       setOpenModal(false);
  //       floatMenu.style.display = 'block';
  //     });
  // }

  // function filter(currentNode) {
  //   return currentNode.tagName !== 'i';
  // }
  // function exportSvg() {
  //   const floatMenu = document.getElementById('float-menu');
  //   floatMenu.style.display = 'none';
  //   const currentLayer = document.querySelectorAll(`[data-key="${node}"]`);
  //   // const node = treeRef.current;
  //   htmlToImage
  //     .toSvg(currentLayer[0])
  //     .then(function (dataUrl) {
  //       const link = document.createElement('a');
  //       link.download = `${boardName}.svg`;
  //       link.href = dataUrl;
  //       link.click();
  //       setIsLoading(false);
  //       setOpenModal(false);
  //     })
  //     .catch(() => {
  //       setOpenModal(false);
  //       setIsLoading(false);
  //     })
  //     .finally((floatMenu.style.display = 'block'));
  // }
  return (
    <div className="modal-sharing-container box-shadow">
      <div className="modal-sharing-top-container">
        <div className="modal-sharing-header">
          <div className="modal-sharing-tabs">
            {sharingModalTabs.map((i) => (
              <div
                onClick={() => setSelectedTab(i.text)}
                className={`
                cursor-pointer modal-sharing-tab-item
                 hover font-14-med 
                 ${selectedTab === i.text ? 'modal-sharing-tab-active' : ''}
                 `}
              >
                {i.text}
              </div>
            ))}
          </div>
          <div
            className="cursor-pointer hover modal-sharing-close"
            onClick={() => setOpenModal(false)}
          >
            {SvgSelector('close')}
          </div>
        </div>
        {selectedTab === 'Share' && (
          <div>
            <div className="modal-sharing-share-container padding-md">
              <div className="font-14-med">Public link</div>
              <div className="modal-sharing-share-link">
                <ModalSharingCopyLink
                  shareLayer={shareLayer}
                  node={node}
                  copyLink={copyLink}
                />
              </div>
              <div className="modal-sharing-share-fast-export">
                <ModalSharingFastExport
                  node={node}
                  selectItem={selectItem}
                  selectedItem={selectedItem}
                  selectFastExport={selectFastExport}
                  exportHandler={exportHandler}
                />
              </div>
            </div>
            <div className="modal-sharing-buttons">
              <div className="modal-sharing-link-button hover cursor-pointer font-14-med">
                <div>{SvgSelector('link', 'modal-sharing-icon')}</div>
                <div>Copy link</div>
              </div>
              <Button
                color={`${selectedFastExport ? 'blue_light-2' : 'gray_06'}`}
                style={{ height: 36, width: 70 }}
                label="Export"
                textColor={`${selectedFastExport ? 'blue_strong' : 'gray_03'}`}
                labelClass="font-14-bold"
                onClick={exportHandler}
              />
            </div>
          </div>
        )}
        {selectedTab === 'Invite' && (
          <ModalSharingInvite
            node={node}
            showNotify={showNotify}
            setOpenModal={setOpenModal}
          />
        )}
        {selectedTab === 'Export' && (
          <ModalExport
            selectItem={selectItem}
            selectedItem={selectedItem}
            exportHandler={exportHandler}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
}

export default ModalSharing;
